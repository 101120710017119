<template>
    <div class="anchor-container">
      <search-card />
    </div>
</template>

<script>

import { 
searchCard
} from '@/components/manage/affiliated'
export default {
  name: "anchor",
  components: {
    searchCard
  },
  data() {
    return {

    };
  },
  computed: {
  },
  mounted(){

  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.anchor-container {
  width: 100%;

}
</style>
