<template>
  <div class="card-container">
    <le-card width="98%" height="270px" >
      <template>
        <div class="container">
          <div class="title-box">
            <div class="title" >{{ title }}</div>
            <div class="time">{{ time }}</div>
          </div>
          <div class="label-box">
            {{ label1 }}
          </div>
          <div class="label-box">
            {{ label2 }}
          </div>

          <div class="progess-box">
            <div style="width: 210px">
              完成职业主播数：{{plabel1}}个
            </div>
            <div class="progess" >
              <a-progress :strokeWidth="15" :percent="pro1" :show-info="false"  />
              <div class="num-box" style="display: flex;justify-content: space-between; ">
                <div  >{{num1}}</div>
                <div  >{{num2}}</div>
              </div>
            </div>
          </div>

          <div class="progess-box">
            
            <div style="width: 210px">
             完成流水：{{ plabel2}} 元 
            </div>

            <!-- <div style="width: 210px" v-if="status==1">
             完成增长流水：{{ num4-num3 }} 元 
            </div>
            <div style="width: 210px" v-else>
             完成流水：{{ plabel2}} 元 
            </div> -->

            <div class="progess" >
              <a-progress :strokeWidth="15" :percent="pro2" :show-info="false" />
              <div class="num-box" style="display: flex;justify-content: space-between; ">
                <div  >{{num3}}</div>
                <div  >{{num4}}  <span v-if="status==1">(x1.5)</span></div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </le-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      pro1:0,
      pro2:0
    };
  },
  props: {
    title: {
      type: String,
      default: "基线任务",
    },
    time: {
      type: [String, Number],
      default: "",
    },
    status: {
      type: [String, Number],
      default: "0",
    },
    label1: {
      type: String,
      default: "",
    },
    label2: {
      type: String,
      default: "",
    },
    plabel1: {
      type: [String, Number],
      default: "",
    },
    plabel2: {
      type: [String, Number],
      default: "",
    },
    num1: {
      type: [String, Number],
      default: 0,
    },
    num2: {
      type: [String, Number],
      default: 0,
    },
    num3: {
      type: [String, Number],
      default: 0,
    },
    num4: {
      type: [String, Number],
      default: 0,
    }
  },
  computed: {},
  mounted() {
    if (this.num2!=0 && this.num2) {
      this.pro1 = (this.plabel1/this.num2)*100
    }

    if (this.num4!=0 && this.num4) {
      if (this.status==1) {
        this.pro2 = ((this.plabel2-this.num3)/(this.num4-this.num3))*100
      }else{
        this.pro2 = (this.plabel2/this.num4)*100
      }
    }
    
  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
.card-container {
  margin-left: 30px;
  .container {
    position: relative;
    .title-box {
      display: flex;
      align-items:flex-end;
      color: #333333;
      padding-top: 10px;
      padding-left: 20px;
      .title{
        font-size: 20px;
        font-weight: bold;
      }
      .time{
        font-size: 12px;
        margin-left: 5px;
      }
    }
    .label-box {
      margin-left: 30px;
      margin-top: 5px;
    }
    .progess-box{
      height: 70px;
      display: flex;
      align-items: center;
      background: #f8f9fd;
      margin: 0 30px;
      padding-left: 30px;
      margin-top: 10px;
      .progess{
        width: 75%;
        margin-top: 20px;
        .num-box{
          font-size: 12px;
          color: #333333;
        }
      }




    }



  }
}
</style>
