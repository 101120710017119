<template>
    <div class="dashboard-search-card-container ">
      <le-card width="100%" height="800px" >
        <template>
          <div class="container">
            <div class="title-box">
              <div class="card-title" style="padding-top: 0;"  >任务数据   <span style="font-size: 14px;margin-left: 10px;">{{resData.guild_name}}</span></div>
            </div>

            <!-- <div class="tips-box">统计时间：2022-07-25 00:00~2022-07-26 00:00(今日数据有一定延迟，以次日结果为准)</div> -->
            <div class="card-box">
              <card  
                title="八月职业主播基线"
                :num="resData.expectation_professional_anchor"
                tips="时间段总交易流水"
                unit="人"
              />
              <card 
                title="八月流水基线"
                :num="resData.expectation_flow"
                tips="时间段旗下主播开播数量"
                unit="元"
              />
            </div>

            <div  class="list-box">
              <list-card  
                title="基线任务"
                :time="resData.start_at+'-'+resData.close_at"
                label1="职业主播标准：月流水 >= 5000元可以评定为公会职业主播"
                label2="任务标准：基础分成指数 50%，完成下面两条标准，分成增加10%"
                :plabel1="resData.now_professional_anchor"
                :plabel2="resData.now_flow"
                :num1="0"
                :num2="resData.expectation_professional_anchor"
                :num3="0"
                :num4="resData.expectation_flow"
                v-if="resData.start_at"
              />
              <list-card 
                title="流水增长任务"
                :time="resData.start_at+'-'+resData.close_at"
                label1="在完成公会基线任务的基础上，完成流水增长任务"
                label2="任务标准：基础分成指数 50%，完成下面两条标准，分成增加10%"
                :plabel1="resData.now_professional_anchor"
                :plabel2="resData.now_flow"
                :num1="0"
                :num2="resData.expectation_professional_anchor"
                :num3="resData.expectation_flow"
                :num4="resData.objective_flow"
                :status="1"
                v-if="resData.start_at"
              style="margin-top: 20px"  />
            </div>

          </div>
        </template>
      </le-card>
    </div>
</template>

<script>
import tool from '@/utils/tool'
import card from './card.vue'
import listCard from './listCard.vue'
export default {
  components: { 
    card,
    listCard
  },
  data() {
    return {
      json:{
        total:0,
        data:0
      },
      resData:{}
    };
  },
  computed: {
  },
  activated(){
    this.userData();
  },
  mounted(){
    tool.$on('statistics',(res)=>{
      this.json=res;
    })
    this.userData();
  },
  methods: {
    userData(){
      this.$api.post('v2/guildManage/userData').then(res=>{
        console.log(res);
        if(res.code==200){
          this.resData=res.data;
          if(this.resData.start_at){
            this.resData.start_at=this.resData.start_at.slice(0, 10)
          }
          if(this.resData.close_at){
            this.resData.close_at=this.resData.close_at.slice(0, 10)
          }
        }else{
          this.$message.error(res.message);
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.dashboard-search-card-container {
.container{
  position: relative;
  .title-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
  .card-box{
    display: flex;
    margin-top: 10px;
  }
  .list-box{
    padding-top: 20px;
    height: 600px;
  }


}
}


@media screen and(max-width: 750px) {
.dashboard-search-card-container {
.container{

}
}
}

</style>
