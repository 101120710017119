<template>
  <div class="dashboard-table-card-container">
    <le-card width="100%" style="padding-bottom: 20px">
      <template>
        <div class="container">
          <div class="title-box">
            <div class="card-title" style="display: flex; padding-top: 0px">
              主播列表
              <div class="num-box">{{ pageOptions.total }}人</div>
            </div>
            <le-tabs style="margin-left: 30px" @change="changeTab" />
          </div>

          <div class="search-box">
              <a-input-search
                    enter-button="搜索"
                    style="width: 300px;"
                    placeholder="请输入搜索内容"
                    @search="onSearch"
                    :allowClear="true"
                  >
              <a-select slot="addonBefore" v-model="type" style="width: 90px">
                  <a-select-option value="uname">
                    昵称
                  </a-select-option>
                  <a-select-option value="uid">
                    UID
                  </a-select-option>
                </a-select>
                </a-input-search>
          </div>

          <div class="tips-box">
            统计时间：{{dateData.start}}~{{dateData.end}}(今日数据有一定延迟，以次日结果为准)
          </div>
          <div class="table-box">
            <a-table
              :columns="columns"
              :data-source="dataSource"
              :loading="loading"
              :pagination="pageOptions"
              @change="handleTableChange"
              size="middle"
            >
              <div slot="uname" slot-scope="row">
                <a
                  class="name-box"
                  :href="'https://space.bilibili.com/' + row.uid"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="name-uid">
                    <div>{{ row.uname }}</div>
                    <div>UID:{{ row.uid }}</div>
                    <div>roomID:{{ row.room_id }}</div>
                  </div>
                </a>
              </div>

              <div slot="income" slot-scope="row">
                {{(row.total_income/1000)*2}}
              </div>

              <div slot="money" slot-scope="row">
                {{row.total_income/1000}}
              </div>

              <div slot="superior" slot-scope="row">
                <div class="name-uid">
                    <div>{{ row.superior }}</div>
                    <div>{{ row.superior_uid }}</div>
                </div>
              </div>
            </a-table>
          </div>
        </div>
      </template>
    </le-card>
  </div>
</template>

<script>
const columns = [
  {
    title: "昵称/UID",
    scopedSlots: { customRender: "uname" },
    width: 200,
  },
  {
    title: "经纪人",
    scopedSlots: { customRender: "superior" },
  },
  {
    title: "金仓鼠",
    dataIndex: "total_income"
  },
  {
    title: "流水",
    scopedSlots: { customRender: "income" }
  },
  {
    title: "收益",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "粉丝总数",
    dataIndex: "fans_count"
  },
  {
    title: "观看时长",
    dataIndex: "watch_time"
  },
  {
    title: "开播有效时 (分钟)",
    dataIndex: "valid_live_time"
  },
  {
    title: "弹幕总数量",
    dataIndex: "dm_num"
  },
  {
    title: "查询时间",
    width: 150,
    dataIndex: "created_at",
  }
];
import tool from '@/utils/tool'
import { MixinList } from "./mixin";
import moment from 'moment';
export default {
  components: {
  },
  data() {
    return {
      columns,
      dataUrl: "v2/anchor/manage",
      delUrl: "admin/layout/del",
      visible: false,
      dateData:{
        start: '',
        end: '',
      },
      type: 'uname',
    };
  },
  computed: {},
  mixins: [MixinList],
  created(){
    this.dateData.start =moment(new Date()).add(-1, 'days').format('YYYY-MM-DD 00:00:00')
    this.dateData.end=moment(new Date()).add(-1, 'days').format('YYYY-MM-DD 23:59:59')
  },
  beforeMount() {
  },
  mounted() {

  },
  methods: {
    //搜索
    onSearch(value) {
      
      if(!value){
        delete this.queryParam.keyword
        delete this.queryParam.search_by
      }else{
      this.queryParam.keyword =value
      this.queryParam.search_by = this.type
      }
      this.queryParam.page = 1;
      console.log(this.queryParam);
      this.getData();
    },


    changeTab(json) {
      console.log(json);
      this.dateData.start =json.start
      this.dateData.end=json.end
      this.queryParam.start_at = encodeURI(json.start);
      this.queryParam.close_at = encodeURI(json.end);
      this.queryParam.page = 1;
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.dashboard-table-card-container {
  .container {
    position: relative;
    .title-box {
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
    }

    .search-box{
      display: flex;
      justify-content: space-between;
      margin: 20px 30px;
    }


    .num-box {
      font-size: 14px;
      margin-left: 11px;
      line-height: 32px;
    }

    .tips-box {
      margin-top: 20px;
      margin-left: 30px;
      font-size: 12px;
      color: #aaa;
    }

    .table-box {
      margin: 40px 30px;
      margin-top: 10px;
    }
  }
}

.btn-canle {
  width: 70px;
  height: 30px;
  background: linear-gradient(-10deg, #fd8a8f, #fa6a9d);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  color: #fff;
  margin-left: 10px;
}

.login {
  cursor: pointer;
  &:hover {
    color: #008df0;
  }
}

.name-box {
  display: flex;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

@media screen and(max-width: 750px) {
  .dashboard-table-card-container {
    .container {
      .title {
        padding-top: 10px;
        margin-left: 20px;
        font-size: 20px;
        .num-box {
          font-size: 12px;
          margin-left: 5px;
          line-height: 22px;
        }
      }
      .le-button {
        position: absolute;
        top: 30px;
        right: 20px;
      }

      .table-box {
        margin: 20px;
        overflow-x: scroll;
      }
    }
  }
}
</style>
